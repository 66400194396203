import React, { useState } from 'react';
import { useAsync } from 'react-async';

import './App.css';

import Data from './Data';

import Match from './components/Match';
import Total from './components/Total';

import logo from './assets/logo.png';
import disclaimer from './assets/disclaimer.png';

// const loadUsers = async () =>
//   await fetch("https://valuebase.io/api/sef/comboapi?market=nl&league=1000094980")
//     .then(res => (res.ok ? res : Promise.reject(res)))
//     .then(res => res.json())

const loadData = async () => {
  const queryParams = new URLSearchParams(window.location.search);
  let market = queryParams.get('market');
  let league = queryParams.get('league');
  let matches = queryParams.get('matches');
  let bets = queryParams.get('bets');

  if (market == null) market = "nl";
  if (league == null) league = "";
  if (matches == null) matches = 5;
  if (bets == null) bets = 5;

  return Data.loadMatches(market, league, matches, bets);
}

function App() {
  let { data, isLoading } = useAsync({ promiseFn: loadData });
  const [coupon, setCoupon] = useState(null);
  const [timestamp, setTimestamp] = useState(0);

  // setInterval(async () => {
  //   data = await Data.loadMatches();
  // }, 5000);

  const updateCouponData = (newData) => {
    let newCoupon = newData;

    if (coupon) {
      coupon.matches.forEach(match => {
        if (match.selectedBet) {
          let newCouponMatch = newCoupon.matches.find((m) => m.unibetId === match.unibetId);
          if (newCouponMatch)
            newCoupon.selectedBet = newCouponMatch.bets.find((m) => m.betId === match.selectedBet.betId);
        }
      });
    } else {
      newCoupon.matches.forEach(match => {
        match.selectedBet = match.bets[0];
      });
    }

    setCoupon(newCoupon);
  };

  const handleCallback = (data) => {
    setTimestamp(new Date());
  };

  if (isLoading) return (<div className="loading">Loading...</div>);
  // if (error) return `Something went wrong: ${error.message}`;

  if (coupon == null && data != null) {
    updateCouponData(data);
  }

  if (coupon) {
    return (
      <>
        <header>
          Favoriet bij spelers van Unibet
        </header>
        <div className="App">
          <main>
            <section className="matches">

                        {coupon.matches.map((match, index) => (
                            <Match key={match.unibetId} timestamp={timestamp} props={match} index={index} parentCallback={handleCallback} />
              ))}

            </section>
            <Total timestamp={timestamp} props={coupon} />
          </main>
          <footer>
            <img src={logo} alt="logo" />
            <img src={disclaimer} alt="disclaimer" />
          </footer>
        </div>
      </>
    );
  }
}

export default App;
