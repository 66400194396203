import React, { useEffect, useState } from 'react';

import close from './../assets/close.png';
import uarr from './../assets/uarr.png';
import darr from './../assets/darr.png';

const Match = ({ props, index, parentCallback }) => {
    const [active, setActive] = useState(true);
    const [betIndex, setBetIndex] = useState(0);

    let bet = props.bets[betIndex];

    const prevBet = () => {
        if (betIndex <= 0)
            setBetIndex(props.bets.length - 1);
        else
            setBetIndex(betIndex - 1);
    }

    const nextBet = () => {
        if (betIndex + 1 >= props.bets.length)
            setBetIndex(0);
        else
            setBetIndex(betIndex + 1);
    }

    const toggleActive = () => {
        setActive(!active);
    }

    const activate = (e) => {
        if (!active)
            setActive(true);
    };

    useEffect(() => {
        props.active = active;
        props.selectedBet = bet;

        parentCallback(props);
        // eslint-disable-next-line
    }, [active, bet, props]);

    if (bet == null)
        return (null);

    return (
        <div className={`match ${active ? 'active' : 'disabled'}`} data-match-id="@match.UnibetId" data-match-index="@matchIndex" data-bet-index="0" onClick={activate}>

            <div className="bet">
                <div className="bet-left">
                    <div className="bet-left-top">
                        <button className="close" onClick={toggleActive}><img src={close} alt="Disable" /></button>
                        <div className="number">{index + 1}</div>
                    </div>
                    <button className="odds" disabled={true}>{bet.odds}</button>
                </div>

                <div className="bet-center">
                    <div className="title">{bet.predictionText}</div>
                    <div className="text">{bet.text}</div>
                    <div className="game">{props.homeTeam} - {props.awayTeam}</div>
                </div>
                <div className="bet-right">
                    <button className="arrow-button up" onClick={prevBet}><img src={uarr} alt="Up arrow" /></button>
                    <button className="arrow-button down" onClick={nextBet}><img src={darr} alt="Down arrow" /></button>
                </div>
            </div>
        </div>

    );
}

export default Match;