import React, { useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';

const Total = ({ props, timestamp }) => {
  const [disabled, setDisabled] = useState(false);
  const [totalOdds, setTotalOdds] = useState(1);
  const [totalStake, setTotalStake] = useState(10);
  const [totalAmount, setTotalAmount] = useState(0);

  const onStakeChanged = (e) => {
    setTotalStake(e.value);
  };

  const onFocusLost = (e) => {
    if (e.target.value === "")
      setTotalStake(0);
  };

  const placeBets = () => {
    let outcomes = props.matches.filter((m) => m.active && m.selectedBet).map((m) => m.selectedBet.betId);
    let url = "https://b1.trickyrock.com/redirect.aspx?pid=86010805&bid=37517&redirecturl=https://www.unibet.nl/betting/sports/event/100000%23?coupon=combination|" + outcomes + "|" + totalStake + "|replace";
    console.log(url);
    window.open(url, '_blank').focus();
    //window.top.location.href = url;
  };

  useEffect(() => {
    let tempTotalOdds = 1.0;
    if (props?.matches) {
      props.matches.forEach(match => {
        if (match.active && match.selectedBet) {
          tempTotalOdds *= match.selectedBet.odds;
        }
      });

      if (tempTotalOdds !== totalOdds)
        setTotalOdds(tempTotalOdds);

      setTotalAmount(tempTotalOdds * totalStake);

      if (totalOdds > 1.00 && totalStake > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }

    }
  }, [props, timestamp, totalOdds, totalStake]);

  return (
    <div className="total">
      <div className="top">
        <div className="total-odds">
          <div className="label">TOTALE ODDS</div>
          <NumberFormat value={totalOdds.toFixed(2)} disabled={true} />
        </div>
        <div className="total-stake">
          <div className="label">INZET</div>
          <NumberFormat value={totalStake} thousandSeparator={true} prefix={'€ '} onValueChange={onStakeChanged} onBlur={onFocusLost} />
        </div>
        <div className="total-amount">
          <div className="label">OPBRENGST</div>
          <NumberFormat value={totalAmount.toFixed(2)} thousandSeparator={true} prefix={'€ '} disabled={true} />
        </div>
      </div>
      <button className='place-bets' onClick={placeBets} disabled={disabled}>Speel mee</button>
    </div>
  );
}

export default Total;